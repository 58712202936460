<template>
  <div class="container">
    <div style="padding: 0 0 30px 0">
      <!-- <el-carousel type="card" indicator-position="none" :autoplay="false">
        <el-carousel-item v-for="src in bannerList" :key="item">
          <el-image :src="src"></el-image>
        </el-carousel-item>
      </el-carousel> -->
      <VueSlickCarousel style="width: 100%" v-bind="settings" class="content">
        <img
          v-for="item in bannerList"
          :key="item.id"
          class="banner_img_css"
          :src="item.src"
          alt=""
          @click="bannerHandle(item)"
        />

        <template #prevArrow="arrowOption">
          <button type="button" @click="arrowOption.click" class="slick-prev">
            aa
          </button>
        </template>
        <template #nextArrow="arrowOption">
          <button type="button" @click="arrowOption.click" class="slick-next">
            bb
          </button>
        </template>
      </VueSlickCarousel>
    </div>

    <div class="mainBox">
      <!-- 智能咨询 -->
      <div class="consultBox">
        <div class="title">智能咨询</div>
        <div class="content">
          <div class="tabs">
            <div
              :class="['left', consultType === 2 && 'active']"
              @click="consultTypeChange(2)"
            >
              文献查找
            </div>
            <div
              :class="['right', consultType === 1 && 'active']"
              @click="consultTypeChange(1)"
            >
              AI问答
              <i class="el-icon-lock"></i>
            </div>
          </div>
          <div class="textAreaBox">
            <el-input
              type="textarea"
              :rows="3"
              :placeholder="consultPlaceholder"
              v-model="search"
              @keydown.native="handleKeydown"
            />
          </div>
          <div class="operate">
            <el-tooltip
              :open-delay="500"
              class="item"
              effect="dark"
              content="图片识别"
              placement="top"
            >
              <!--  action="https://ql-test.newacademic.net/api/literature/ImageOcr/" 
              https://xcx.newacademic.net/api/ -->
              <el-upload
                class="upload-demo"
                name="img"
                :action="action_url"
                :headers="headers"
                :on-success="succesfile"
                :auto-upload="true"
                :show-file-list="false"
                list-type="picture"
                accept=".jpg,.png,.gif,.jpeg"
                :style="{
                  visibility: consultType === 2 ? 'visible' : 'hidden',
                }"
              >
                <img class="icon" src="../../assets/image/upload.png" alt="" />
              </el-upload>
            </el-tooltip>
            <div class="operate_right">
              <div class="operate_txt">shift+enter：换行输入 enter：提交</div>
              <el-button
                :class="[search.trim() && 'active']"
                @click="consultHandle"
                >{{ consultType === 2 ? "提交" : "提交" }}</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <!-- 常用功能 -->
      <div class="commonBox" v-if="model_or()">
        <div class="title">常用功能</div>
        <div class="content">
          <el-row type="flex" class="row-bg" :gutter="16">
            <el-col :span="6">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/repet_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/repet.png" alt="" />
                  <span class="text">英文查重</span>
                </div>
                <div class="desc">学术不端检测系统</div>
                <div class="abstract">
                  快速检测论文重复率，依托庞大数据库，避免学术不端风险，保障学术诚信
                </div>
                <el-button
                  @click="
                    subMenuChange({
                      id: 31,
                      title: '英文查重',
                      path: '/dissertation?webPage=1',
                      parentId: 3,
                    })
                  "
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="6">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/proof_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/proof.png" alt="" />
                  <span class="text">语法校对</span>
                </div>
                <div class="desc">语法检查系统</div>
                <div class="abstract">
                  帮助改进论文的措辞、语法与学术表达，提高写作表意的清晰度
                </div>
                <el-button
                  @click="
                    subMenuChange({
                      id: 32,
                      title: '语法校对',
                      path: '/dissertation?webPage=2',
                      parentId: 3,
                    })
                  "
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div class="translateBox">
                <div class="header">
                  <div class="left">
                    <img src="../../assets/image/translate.png" alt="" />
                    <span class="text">文档翻译</span>
                    <span class="desc">文档翻译，跨语言沟通桥梁</span>
                  </div>
                  <div class="right" @click="viewReport">查看报告</div>
                </div>
                <div class="language">
                  <div class="left">
                    <el-select
                      :value="originLanguage"
                      @change="originLanguageChange"
                    >
                      <el-option
                        v-for="item in languageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="switch" @click="switchChange">
                      <img src="../../assets/image/switch.png" alt="" />
                    </div>
                    <el-select
                      :value="targetLanguage"
                      @change="targetLanguageChange"
                    >
                      <el-option
                        v-for="item in languageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="right" @click="translateHandle">
                    <el-button
                      :class="[fileList.length > 0 && 'active']"
                      :loading="uploadLoading"
                      >翻译</el-button
                    >
                  </div>
                </div>
                <div class="fileList">
                  <el-upload
                    ref="upload"
                    class="uploadBox"
                    drag
                    action="https://eastrans.newacademic.net/api/translate/upload/"
                    multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                    :auto-upload="false"
                    name="file_obj"
                    :on-success="uploadSuccess"
                    :on-error="uploadFail"
                    :data="{
                      langFrom: originLanguage,
                      langTo: targetLanguage,
                      file_num: fileList.length,
                    }"
                    :headers="myHeaders"
                    :show-file-list="false"
                    :on-change="handleChange"
                  >
                    <div v-if="fileList.length === 0" class="el-upload__text">
                      <div class="info">点击或将文件拖到此处</div>
                      <div class="iconBox">
                        <img src="../../assets/image/ppt.png" alt="" />
                        <img src="../../assets/image/word.png" alt="" />
                        <img src="../../assets/image/pdf.png" alt="" />
                        <img src="../../assets/image/excel.png" alt="" />
                        <img src="../../assets/image/txt.png" alt="" />
                      </div>
                    </div>
                    <div v-else class="fileBox">
                      <div
                        v-for="(item, index) in fileList"
                        :key="index"
                        class="fileItem"
                      >
                        <div class="close" @click.stop="delFilePre(index)">
                          <img src="../../assets/image/close.png" alt="" />
                        </div>
                        <div class="left">
                          <img
                            v-if="['pptx', 'ppt'].includes(item.suffix)"
                            src="../../assets/image/ppt.png"
                            alt=""
                          />
                          <img
                            v-if="['docx', 'doc'].includes(item.suffix)"
                            src="../../assets/image/word.png"
                            alt=""
                          />
                          <img
                            v-if="item.suffix === 'pdf'"
                            src="../../assets/image/pdf.png"
                            alt=""
                          />
                          <img
                            v-if="['xlsx', 'xls'].includes(item.suffix)"
                            src="../../assets/image/excel.png"
                            alt=""
                          />
                          <img
                            v-if="item.suffix === 'txt'"
                            src="../../assets/image/txt.png"
                            alt=""
                          />
                        </div>
                        <div class="right">
                          <span class="name">{{ item.name }}</span>
                          <div class="info">
                            <div class="suffix">{{ item.suffix }}</div>
                            <div class="size">
                              {{ (item.size / 1024).toFixed(2) }}kb
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="fileList.length < 10" class="chooseFile">
                        <i class="el-icon-plus"></i>
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 拓展功能 -->
      <div class="expandBox" v-if="model_or()">
        <div class="title">拓展功能</div>
        <div class="content">
          <el-row type="flex" class="row-bg" :gutter="16">
            <!-- <el-col :span="6">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/read_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/read.png" alt="" />
                  <span class="text">优选阅读</span>
                </div>
                <div class="desc">优选阅读，让知识触手可及</div>
                <div class="abstract">
                  精选畅销榜单、海量正版资源、广泛学科品类
                </div>
                <el-button>立即使用</el-button>
              </div>
            </el-col> -->
            <el-col :span="6">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/push_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/push.png" alt="" />
                  <span class="text">学术推送</span>
                </div>
                <div class="desc">学术推送，您的个性化学术资讯中心</div>
                <div class="abstract">
                  个性化定制，推送领域内最新研究成果，第一时间获取前沿资讯
                </div>
                <el-button
                  @click="
                    subMenuChange({
                      id: 51,
                      title: '中文期刊推荐',
                      path: '/academic?webPage=1',
                      parentId: 5,
                    })
                  "
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="6">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/quot_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/quot.png" alt="" />
                  <span class="text">引文追踪</span>
                </div>
                <div class="desc">引文追踪，让学术影响力一目了然</div>
                <div class="abstract">
                  系统监控科研成果影响力，实时追踪论文被引用情况，全面掌握发文数据
                </div>
                <el-button
                  @click="
                    subMenuChange({
                      id: 61,
                      title: '中文文献追踪',
                      path: '/citation?webPage=1',
                      parentId: 6,
                    })
                  "
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="6">
              <div class="itemBox">
                <img class="back" src="../../assets/image/ai_back.png" alt="" />
                <div class="header">
                  <img src="../../assets/image/ai.png" alt="" />
                  <span class="text">AI馆员</span>
                </div>
                <div class="desc">让图书馆服务更智能，让学习更轻松</div>
                <div class="abstract">
                  AI馆员，智能检索，快速响应，优化图书馆服务体验
                </div>
                <el-button @click="handleSelect('AI馆员')">立即使用</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 已购资源发现 -->
      <div
        class="productBox"
        v-if="model_or() && mes.organ_product && mes.organ_product.length > 0"
      >
        <div class="title">已购资源发现</div>
        <div class="content">
          <el-row type="flex" class="row-bg" :gutter="16">
            <el-col :span="6" v-if="resource_or(25)">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/know_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/know.png" alt="" />
                  <span class="text">新知发现</span>
                </div>
                <div class="desc">新知发现，拓宽视野，激发创新思维</div>
                <div class="abstract">
                  新知发现，一站式知识平台，探索、学习、分享，让智慧触手可及
                </div>
                <el-button @click="handleSelect('新知发现')"
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="6" v-if="resource_or(1)">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/journal_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/journal.png" alt="" />
                  <span class="text">期刊发现</span>
                </div>
                <div class="desc">让研究视野更广阔，让知识更丰富</div>
                <div class="abstract">
                  期刊发现，智能推荐，精准匹配，让学术探索更高效
                </div>
                <el-button @click="handleSelect('期刊发现')"
                  >立即使用</el-button
                >
              </div>
            </el-col>
            <el-col :span="6" v-if="resource_or(14)">
              <div class="itemBox">
                <img
                  class="back"
                  src="../../assets/image/paper_back.png"
                  alt=""
                />
                <div class="header">
                  <img src="../../assets/image/paper.png" alt="" />
                  <span class="text">学位论文发现</span>
                </div>
                <div class="desc">探索学术宝藏，让研究更进一步</div>
                <div class="abstract">
                  学位论文发现，一站式学术资源，精准检索，助力研究深入
                </div>
                <el-button @click="handleSelect('学位论文发现')"
                  >立即使用</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguages } from "@/Api/translation";
import VueSlickCarousel from "vue-slick-carousel";
import { personalMessage, fetchMessage } from "@/Api/userMessage";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        centerMode: true,
        centerPadding: "15%",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        // dots: true,
        // autoplay: true,
        autoplay: false,
        arrows: true,
        draggable: false,
      },
      bannerList: [
        {
          id: 4,
          name: "",
          src: require("../../assets/imgs/banner/one_banner.png"),
        },
        {
          id: 1,
          name: "use",
          src: require("../../assets/imgs/banner/Component 19.png"),
        },
        {
          id: 3,
          name: "cool",
          path: "http://addons.dic.cool",
          src: require("../../assets/imgs/banner/Component 20.png"),
        },
        {
          id: 2,
          name: "issuse",
          src: require("../../assets/imgs/banner/Component 21.png"),
        },
        // { id: 4, name: '', src: require("../../assets/imgs/banner/Component 21.png") },
      ],
      consultType: 2, // 1 - 文献查找 2 - 智能咨询
      search: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      myHeaders: { USID: window.localStorage.getItem("token_USID") },
      languageOptions: [], // 语言选择数据
      originLanguage: "en",
      targetLanguage: "zh",
      fileList: [], // 上传文件列表
      uploadLoading: false, // 上传loading
      disable_group_s: [
        "39053605528@chatroom",
        "56530986784@chatroom",
        "52571019643@chatroom",
        "38696601715@chatroom",
        "35034516726@chatroom",
        "38802985139@chatroom",
        "39227070897@chatroom",
        "20679458060@chatroom",
        "34517104569@chatroom",
        "34903209947@chatroom",
        "34496301855@chatroom",
        "44071109340@chatroom",
        "44558909279@chatroom",
        "49983741456@chatroom",
        // "44094707619@chatroom",
        "45496379845@chatroom",
        "44552503109@chatroom",
        "44910989359@chatroom",
        "39244616932@chatroom",
        "39304327028@chatroom",
        "22582410266@chatroom",
        "34462242436@chatroom",
        "38678517676@chatroom",
        "22018629545@chatroom",
        "23843205420@chatroom",
        "25639799054@chatroom",
        "25206716703@chatroom",
        // "23887022323@chatroom",
        "25094686337@chatroom",
        "25682179786@chatroom",
        "23524403013@chatroom",
        "23525613804@chatroom",
        "24166403217@chatroom",
        "22491014284@chatroom",
        "23108123075@chatroom",
        "24252511613@chatroom",
        "25107783359@chatroom",
        "20874107163@chatroom",
        "22337408040@chatroom",
        "22604806822@chatroom",
        // "24986167058@chatroom",
        "17414825312@chatroom",
        // "25295060073@chatroom",
        "23992692252@chatroom",
        "20231297349@chatroom",
        "20553498375@chatroom",
        "14029975295@chatroom",
        "20126095437@chatroom",
        "17227369353@chatroom",
      ],
      mes: {
        belong_id: "",
      },
      action_url: process.env.VUE_APP_API_BASE_URL + "literature/ImageOcr/",
    };
  },
  created() {
    if (sessionStorage.getItem("mes")) {
      this.mes = JSON.parse(sessionStorage.getItem("mes"));
      if (this.mes.is_xuanniao) {
        this.bannerList.unshift({
          id: 5,
          name: "xuanniao",
          path: "https://xuanniao.newacademic.net/",
          src: require("../../assets/imgs/banner/xuanniao_banner.png"),
        });
      }
    } else {
      this.message();
    }
    // this.message();
    this.getLanguageOption();
  },
  computed: {
    consultPlaceholder() {
      if (this.consultType === 1) {
        return `您可以提问任何学术相关的问题，例如：“如何撰写一篇高质量的文献综述”“如何确定研究课题？”（注意：“AI问答”不处理文献类需求，文献类型需求请提交至“文献查询”）`;
      } else {
        return `可批量查询文献，每条需求以换行结束，需求示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`;
      }
    },
  },
  methods: {
    message() {
      personalMessage()
        .then((res) => {
          sessionStorage.setItem("mes", JSON.stringify(res.data.data));
          this.mes = res.data.data;
          if (this.mes.is_xuanniao) {
            this.bannerList.unshift({
              id: 5,
              name: "xuanniao",
              path: "https://xuanniao.newacademic.net/",
              src: require("../../assets/imgs/banner/xuanniao_banner.png"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    model_or() {
      console.log(this.disable_group_s.includes(this.mes.belong_id));
      return !this.disable_group_s.includes(this.mes.belong_id);
    },
    bannerHandle(item) {
      if (!item.name) {
        return;
      }
      if (item.id === 5) {
        // item.path = "http://192.168.66.34:5173/";
        if (sessionStorage.getItem("xn_user_aes")) {
          item.path =
            item.path +
            `?iv=${encodeURIComponent(sessionStorage.getItem("xn_user_aes"))}`;
        }
        window.open(item.path, "_blank");
      } else if (item.id === 3) {
        window.open(item.path, "_blank");
      } else {
        this.$router.push({
          name: "Personal",
          query: { webPage: 4, type: item.name },
        });
      }
    },
    resource_or(id) {
      console.log(this.mes.organ_product);
      const index = this.mes.organ_product.findIndex((item) => item.id === id);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    },
    subMenuChange(subItem) {
      if (subItem.id === this.subMenuCurrent) return;
      let subMenuCurrent = subItem.id;
      let firstMenuCurrent = subItem.parentId;
      sessionStorage.setItem(
        "menuCurrent",
        JSON.stringify({
          firstMenuCurrent: firstMenuCurrent,
          subMenuCurrent: subMenuCurrent,
        })
      );
      this.$router.push({ path: subItem.path });
    },
    handleSelect(key) {
      let id_photo_state = "";
      if (sessionStorage.getItem("id_photo_state")) {
        id_photo_state = sessionStorage.getItem("id_photo_state");
      } else {
        id_photo_state == "2";
      }
      let is_idphoto = this.mes.is_idphoto;
      let unionid = sessionStorage.getItem("unionid");
      let org_ode = localStorage.getItem("org_ode");
      if (key == "AI馆员") {
        return this.$message.warning("极速升级，即将呈现，敬请期待！");
        if (id_photo_state == "2") {
          window.open("https://chatanswer.newacademic.net/", "_blank");
        } else {
          this.open_confirm();
        }
      } else if (key == "新知发现") {
        if (id_photo_state == "2" || !is_idphoto) {
          window.open(
            `https://scholar.newacademic.net/#/home?user_id=${unionid}&organ_code=${org_ode}&source=ql&type=ql`,
            "_blank"
          );
        } else {
          this.open_confirm();
        }
      } else if (key == "学位论文发现") {
        if (id_photo_state == "2" || !is_idphoto) {
          window.open(
            `https://dissertation.newacademic.net/#/index?source=ql&type=ql&unionid=${unionid}&org_ode=${org_ode}`,
            "_blank"
          );
        } else {
          this.open_confirm();
        }
      } else if (key == "期刊发现") {
        if (id_photo_state == "2" || !is_idphoto) {
          window.open(
            `https://search.newacademic.net/#/index?source=ql&type=ql&user_id=${unionid}&organ_code=${org_ode}`,
            "_blank"
          );
        } else {
          this.open_confirm();
        }
      }
    },
    open_confirm() {
      this.$confirm(
        "该功能需进行身份验证，请前往微信小程序完善您的证件信息。感谢您的配合与理解！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
          // showConfirmButton: false,
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    // 获取语言的选择数据
    async getLanguageOption() {
      let res = await getLanguages({ aim: "file" });
      if (res.data.status == 0) {
        this.languageOptions = res.data.data;
      } else {
        this.languageOptions = [];
      }
    },
    // 智能咨询类型切换
    consultTypeChange(type) {
      if (type == 1) {
        return this.$message.warning("极速升级，即将呈现，敬请期待！");
      }
      if (this.consultType === type) return;
      this.consultType = type;
    },
    // 智能咨询操作
    consultHandle() {
      let searchText = this.search.trim();
      if (this.consultType === 1) {
        if (!searchText) return;
        sessionStorage.setItem("textarea", searchText);
        this.$router.push({ path: "/search?webPage=3" });
      } else {
        if (!searchText) return;
        sessionStorage.setItem("searchText", searchText);
        this.$router.push({ path: "/search?webPage=1" });
      }
    },
    //ocr 识别回调
    succesfile(res) {
      if (res.error_code == 0) {
        if (res.data[1] == null || res.data[1].length == 0) {
          this.$message.error("无法识别图片中的内容，请重新上传");
        } else {
          let textarea = "";
          res.data[1].map((item) => {
            textarea += item.words + "\n";
          });
          this.search = textarea;
          // sessionStorage.setItem("textareaOcr", textarea);
          // this.$router.push({ path: "/search?webPage=2" });
        }
      } else {
        this.$message.error("无法识别图片中的内容，请重新上传");
      }
    },
    // 查看报告
    viewReport() {
      this.$router.push({ path: "translation?webPage=2" });
    },
    // 原文译文对调
    switchChange() {
      let original_text = this.originLanguage;
      let target_text = this.targetLanguage;
      this.originLanguage = target_text;
      this.targetLanguage = original_text;
    },
    // 原文语言
    originLanguageChange(name) {
      if (name === this.targetLanguage) {
        this.$message.warning("语言不能相同");
        return;
      }
      this.originLanguage = name;
    },
    // 译文语言
    targetLanguageChange(name) {
      if (name === this.originLanguage) {
        this.$message.warning("语言不能相同");
        return;
      }
      this.targetLanguage = name;
    },
    handleChange(file, fileList) {
      this.fileList = fileList.filter((item) => {
        let suffix = item.name
          .substr(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        item.suffix = suffix;
        return [
          "pdf",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
          "txt",
        ].includes(suffix);
      });
    },
    // 文件数量限制
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 翻译之前删除文件
    delFilePre(i) {
      this.$confirm("此操作将删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.fileList.splice(i, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 文件上传成功
    uploadSuccess(res, file, fileList) {
      this.uploadLoading = false;
      if (res.status == 0) {
        this.$message.success("上传成功! 请在查看报告中查看");
        setTimeout(() => {
          this.fileList = [];
        }, 0);
      } else {
        this.$message.error(res.msg || "文件上传失败，请稍后再试");
      }
    },
    // 文件上传失败
    uploadFail(err, file, fileList) {
      this.uploadLoading = false;
      this.$message.error("文件上传失败，请稍后再试");
    },
    // 翻译操作
    translateHandle() {
      let file_num = this.fileList.length;
      if (file_num === 0) return;
      this.uploadLoading = true;
      this.$refs.upload.submit();
    },
    // 确认翻译
    handleTrans() {
      this.uploadLoading = true;
      this.$refs.upload.submit();
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Shift + Enter：换行（默认行为，无需处理）
        } else {
          // Enter：提交内容
          event.preventDefault(); // 阻止默认的回车行为（如提交表单）
          this.consultHandle();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 84px);
  overflow-y: auto;
  padding: 24px 16px 0 16px;

  .mainBox {
    margin-top: 24px 24px 4px 24px;

    .consultBox,
    .commonBox,
    .expandBox,
    .productBox {
      .title::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 22px;
        line-height: 22px;
        margin: 0 4px -4px 0;
        border-radius: 8px;
      }

      .title {
        font-size: 20px;
        font-weight: 500;
        color: #000;
      }
    }

    .consultBox {
      .title::before {
        background-color: #ff5252;
      }

      .content {
        margin-top: 12px;

        .tabs {
          display: flex;
          align-items: center;
          height: 32px;
          line-height: 32px;
          cursor: pointer;

          .left {
            border-top-left-radius: 8px;
          }

          .right {
            border-top-right-radius: 8px;
          }

          .left,
          .right {
            width: 175px;
            text-align: center;
            color: #8d8f94;
            font-size: 14px;
            background-color: #e6e6e6;
            font-weight: 500;
          }

          .active {
            color: #e63b63;
            background-color: #fff;
          }
        }

        .textAreaBox {
          ::v-deep .el-textarea__inner {
            height: 100px;
            border: none;
            resize: none;
            outline: none;
            padding: 12px 15px 4px 15px;
          }
        }

        .operate {
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          border-radius: 0 0 8px 8px;
          padding: 8px 12px 12px 16px;

          .icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          .el-button {
            width: 76px;
            height: 30px;
            line-height: 0;
            background: #f1f2f2;
            color: #a8aaae;
            border: none;
          }

          .active {
            background: #e63b63;
            color: #fff;
          }
        }
      }
    }

    .commonBox,
    .expandBox,
    .productBox {
      margin-top: 24px;

      .content {
        margin-top: 12px;

        .itemBox {
          height: 216px;
          padding: 11px 12px;
          border: 1px solid #e4e4e6;
          border-radius: 8px;
          background-color: #fff;
          position: relative;

          .back {
            position: absolute;
            right: 0;
            top: 0;
          }

          .header {
            display: flex;
            align-items: center;
            gap: 4px;

            img {
              width: 24px;
              height: 24px;
              vertical-align: middle;
            }

            .text {
              font-size: 16px;
              font-weight: 500;
              color: #000;
            }
          }

          .desc {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 500;
            color: #8d8f94;
            line-height: 22px;
          }

          .abstract {
            margin-top: 23px;
            font-size: 12px;
            font-weight: 500;
            color: #000;
            line-height: 22px;
          }

          .el-button {
            position: absolute;
            bottom: 11px;
            height: 54px;
            width: calc(100% - 24px);
            border-radius: 8px;
            background-color: #f5f5f5;
            border: none;
            font-size: 14px;
            color: #262729;
          }
        }
      }
    }

    .expandBox {
      .title::before {
        background-color: #ffb86b;
      }
    }

    .productBox {
      .title::before {
        background-color: #ff6ba1;
      }
    }

    .commonBox {
      .title::before {
        background-color: #7265ff;
      }

      .content {
        .translateBox {
          height: 216px;
          padding: 11px 12px;
          border: 1px solid #e4e4e6;
          border-radius: 8px;
          background-color: #fff;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;
              align-items: center;

              img {
                width: 24px;
                height: 24px;
                vertical-align: middle;
              }

              .text {
                margin-left: 5px;
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .desc {
                margin-left: 18px;
                font-size: 12px;
                font-weight: 500;
                color: #8d8f94;
              }
            }

            .right {
              font-size: 14px;
              color: #262729;
              cursor: pointer;
            }
          }

          .language {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;
              align-items: center;
              gap: 19px;

              .el-select {
                width: 120px;
              }

              ::v-deep .el-input__inner {
                height: 30px;
                background-color: #f5f5f5;
                border: none;
              }

              ::v-deep .el-input__icon {
                line-height: 30px;
              }

              .switch {
                cursor: pointer;

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .right {
              .el-button {
                width: 76px;
                height: 30px;
                color: #a8aaae;
                background-color: #f1f2f2;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .active {
                color: #fff;
                background: #e63b63;
              }
            }
          }

          .fileList {
            margin-top: 16px;

            ::v-deep .el-upload {
              width: 100%;

              .el-upload-dragger {
                width: 100%;
                height: 112px;
                border-radius: 8px;
              }
            }

            .uploadBox {
              .el-upload__text {
                display: flex;
                flex-direction: column;

                .info {
                  margin-top: 22px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #a8aaae;
                }

                .iconBox {
                  margin-top: 22px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                }
              }

              .fileBox {
                padding: 12px;
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .fileItem {
                  position: relative;
                  width: calc(20% - 12px);
                  display: flex;
                  align-items: center;
                  gap: 7px;
                  padding: 4px;
                  border: 1px solid #e4e4e6;
                  border-radius: 6px;

                  .close {
                    position: absolute;
                    right: -8px;
                    top: -8px;

                    img {
                      width: 16px;
                      height: 16px;
                      cursor: pointer;
                    }
                  }

                  .left {
                    width: 24px;

                    img {
                      width: 24px;
                      height: 24px;
                      vertical-align: middle;
                    }
                  }

                  .right {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    width: calc(100% - 32px);

                    .name {
                      font-size: 12px;
                      font-weight: 500;
                      color: #262729;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 100%;
                      line-height: 16px;
                      text-align: left;
                    }

                    .info {
                      width: 100%;
                      line-height: 11px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 8px;
                      color: #6b6e73;
                    }
                  }
                }

                .chooseFile {
                  width: calc(20% - 12px);
                  height: 38px;
                  border: 1px dashed #e4e4e6;
                  border-radius: 6px;
                  cursor: pointer;
                  line-height: 38px;

                  i {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.banner_img_css {
  //   padding: 0 25px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 20px;
  //   height: 240px;
}
/deep/ .slick-next {
  //   right: calc(20% + 5px) !important;
  right: 25% !important;
  top: 50%;
  z-index: 1;
}
//左箭头
/deep/ .slick-prev {
  //   left: calc(20% - 25px)  !important;
  left: 23% !important;
  top: 50%;
  z-index: 1;
}
/deep/ button.slick-prev:before {
  color: rgb(56, 56, 56) !important;
  font-size: 50px;
}
/deep/ button.slick-next:before {
  color: rgb(56, 56, 56) !important;
  font-size: 50px;
}
.arrow-btn {
  border: none;
  img {
    height: 40px;
  }
}
.operate_right {
  display: flex;
  align-items: center;
  .operate_txt {
    color: #c8c8c8;
    margin: 0 20px 0 0;
  }
}
</style>